/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */
import * as microsoftTeams from '@microsoft/teams-js';
import { LayoutContextProvider, changeFavicon, frontoffice, i18next } from '_core';
import { getApiHealth } from '_core/crud/appInfo.crud';
import * as auth from '_core/store/auth.duck';
import * as coreEntities from '_core/store/index';
import * as routerHelpers from '_core/utils/RouterHelpers';
import { isTeams } from '_core/utils/teamsTab';
import { isStudent, isTeacher } from '_core/utils/user';
import { role } from '_core/variables/constant';
import { ROUTES } from 'app/utils/constants/paths.js';
import { notificationsTypes } from 'app/utils/notifications';
import clsx from 'clsx';
import posthog from 'posthog-js';
import React, { Suspense, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { getLanguageByTenant, isLocalhost } from '../../_core/index.js';
import Loading from '../../_core/modules/atoms/Loading/Loading.js';
import { defaultConfig } from '../config/environment';
import { importLanguageResources } from '../utils/languages.js';
import { isEditorial } from '../utils/users';
import { isInPublisher } from '../utils/utils';
import { externalRoutes, localhostRoutes, privateRoutes, publicRoutes } from './routesConfig.js';

const NotFound = React.lazy(() => import('app/pages/ContentNotFoundPage/ContentNotFoundPage'));

export const APP_IS_PUBLISHER = isInPublisher(defaultConfig) || false;
// http://localhost:3005/auth/login/{token}}/deeplink?link=course&38021190-17e6-11ed-a395-0f156a9b1207&program
let callbackToken = null;

export const Routes = withRouter(({ history }) => {
  const lastLocation = useLastLocation();
  const dispatch = useDispatch();

  const [teamsContentUrl, setTeamsContentUrl] = useState('');
  const [isTeamsDark, setIsTeamsDark] = useState(false);
  const [loading, setLoading] = useState(false);

  const theme = useSelector((store) => frontoffice.ui.selectors.getTheme(store));
  const userRoleGuid = useSelector((state) => auth.selectors.getUserRoleGuid(state));
  const scopes = useSelector((state) => coreEntities.langs.selectors.getLangScopes(state));
  const client = useSelector((state) => coreEntities.organization.selectors.getClient(state));
  const tenant = useSelector((state) => coreEntities.organization.selectors.getTenant(state));
  const user = useSelector((state) => auth.selectors.getUser(state));
  const lms = useSelector((state) => coreEntities.organization.selectors.getLms(state));
  checkIfCallbackTokenExists();

  // useEffect(() => {
  //   initAssistant(user, 'TANGERINE');
  // }, []);

  // useEffect(() => {
  //   modifyUser(user);
  // }, [user]);

  useEffect(() => {
    if (user?.guid && tenant?.guid) {
      // initUserForTracking(user, tenant.guid);
      initPosthog();
    }
  }, [user, tenant]);

  const { isAuthorized, country, isChangePassword } = useSelector(
    ({ auth }) => ({
      isAuthorized: callbackToken ? auth.user != null && auth.authToken === callbackToken : auth.user != null,
      hasAuthToken: Boolean(auth.authToken),
      roleUser: (auth.user && auth.user.role_guid) || null,
      userLastLocation: routerHelpers.getLastLocation(),
      country: auth.user?.country_guid,
      license: auth.license,
      isChangePassword: Boolean(auth.changePassword),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (isAuthorized) {
      dispatch(coreEntities.courses.actions.getAllCoursesMessages());
    }
  }, []);

  useEffect(() => {
    if (isAuthorized) {
      checkApiHealth();
      dispatch(frontoffice.notifications.actions.getNotifications({ offset: 0, pageSize: 4, typeNotifications: notificationsTypes.SOCIAL }));
      dispatch(
        frontoffice.notifications.actions.getNotificationsToDo({
          offset: 0,
          pageSize: 4,
          typeNotifications: notificationsTypes.TODO,
        })
      );
      dispatch(frontoffice.notifications.actions.getNotificationsUnread());
      dispatch(frontoffice.notifications.actions.getNotificationsUnreadToDo());
      dispatch(frontoffice.learningObjectives.actions.getLearningObjectives());
      dispatch(frontoffice.ui.actions.getFonts());
    }
    dispatch(frontoffice.educationLevels.actions.getEducationLevels({ country }));
    dispatch(frontoffice.langs.actions.getLangs({ isFilter: true }));
    dispatch(frontoffice.langs.actions.getLangScopes());
  }, [isAuthorized]);

  useEffect(() => {
    if (!lms) dispatch(coreEntities.organization.actions.getOrganization());
  }, [lms]);

  useEffect(() => {
    if (scopes && scopes.length > 0) {
      setAppNameAndFavicon();
    }
  }, [scopes]);

  useEffect(() => {
    if (isTeams()) {
      microsoftTeams.app.initialize().then(() => {
        microsoftTeams.app.getContext().then((context) => {
          setIsTeamsDark(context.app.theme === 'dark');
        });
        microsoftTeams.pages.getConfig().then((pageConfig) => {
          if (pageConfig.contentUrl) {
            setTeamsContentUrl(pageConfig.contentUrl.replace(window.location.origin, ''));
          }
        });
      });
    }
  }, []);

  useEffect(() => {
    dispatch(
      frontoffice.ui.actions.setUiColors({
        color: getComputedStyle(document.documentElement).getPropertyValue('--color-first'),
        colorAlpha: getComputedStyle(document.documentElement).getPropertyValue('--color-first-alpha'),
        colorGradient1: getComputedStyle(document.documentElement).getPropertyValue('--color-gradient-1'),
        colorGradient2: getComputedStyle(document.documentElement).getPropertyValue('--color-gradient-2'),
      })
    );

    dispatch(
      frontoffice.ui.actions.setUiFonts({
        fontFirst: getComputedStyle(document.documentElement).getPropertyValue('--font-first'),
        fontFirstSemi: getComputedStyle(document.documentElement).getPropertyValue('--font-first-semi'),
        fontSecond: getComputedStyle(document.documentElement).getPropertyValue('--font-second'),
        fontSecondBold: getComputedStyle(document.documentElement).getPropertyValue('--font-second-bold'),
      })
    );
  }, []);

  if (lastLocation && isAuthorized) {
    routerHelpers.saveLastLocation(lastLocation);
  }

  useEffect(() => {
    setLoading(true);
    if (tenant?.guid) {
      const defaultLanguage = getLanguageByTenant(tenant?.guid, user?.lang_id);
      const languageKeys = importLanguageResources(tenant, defaultLanguage);

      // Añadimos las traducciones al i18next
      Object.keys(languageKeys).forEach(function (lang) {
        languageKeys[lang].map((langScope) => {
          Object.keys(langScope).forEach(function (langScopeNs) {
            i18next.addResources(lang, langScopeNs, langScope[langScopeNs]);
          });
        });
      });
      i18next.changeLanguage(defaultLanguage);
      dispatch(coreEntities.i18n.actions.setLanguage(defaultLanguage));
      if (user?.lang_id) dispatch(coreEntities.auth.actions.editProfile({ lang_id: defaultLanguage }));
      setLoading(false);
    } else if (isLocalhost() && !tenant?.guid) {
      // Para evitar el loading infinito en localhost ya que...
      // si en localhost no apuntamos al puerto y dominio corecto la llamada org falla
      setLoading(false);
    }
  }, [tenant, user?.lang_id]);

  async function checkApiHealth() {
    const response = await getApiHealth();
    if (response?.status !== 200) {
      history.push('/error');
    }
  }

  //TODO: Añadir un else al title y dejar vacío por defecto para que no se vea mientras no llega scopes.
  function setAppNameAndFavicon() {
    let theName = scopes.filter((scope) => scope.scope === 'name');
    if (theName.length > 0) {
      document.title = theName[0].json;
    }
    let theFavicon = scopes.filter((scope) => scope.scope === 'favicon');
    if (theFavicon.length > 0) {
      changeFavicon(theFavicon[0].json);
    }
  }

  function checkIfCallbackTokenExists() {
    const path = window.location.pathname;
    // We check this in case it comes from the backoffice and we are already logged in with another user
    if (path.includes('/auth/login/')) {
      const splitted = path.split('/');
      callbackToken = splitted.length > 4 ? splitted[splitted.length - 2] : splitted[splitted.length - 1];
    }
    if (path === '/auth/login') {
      callbackToken = null;
    }
  }

  function redirectFromLoginWithToken() {
    const path = window.location.pathname;
    if (path.startsWith('/auth/login/')) {
      const splitted = path.split('/');
      if (splitted.length > 4) {
        const newPath = '/' + splitted[splitted.length - 1].replaceAll('&', '/');
        return newPath;
      }
    }
    return '/home';
  }

  function initPosthog() {
    posthog.identify(user.guid, {
      client: tenant.guid,
      email: user.username,
      name: user.name + ' ' + user.lastname,
      role: userRoleGuid === role.student ? 'student' : 'teacher',
    });

    // if (user.username.includes('@freetrial.com')) {
    //   console.log('inicio grabación');
    //   initSessionReplay();
    // }
  }

  const renderRoutes = (routes) => {
    return routes.map((route, index) => {
      if (route.props) {
        //utilizamos render en lugar de component para pasar props al componente
        return <Route exact key={route.props || index} path={route.path} render={(props) => <route.component {...props} {...route.props} />} />;
      }
      //si no hay props, utilizar component es más eficiente
      return <Route key={index} path={route.path} component={route.component} />;
    });
  };

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <div
      className={clsx(`theme-${theme}`, `client--${client}`, 'app-content', {
        'app-content--publisher': isEditorial(userRoleGuid),
        'app-content--student': isStudent(userRoleGuid),
        'app-content--teacher': isTeacher(userRoleGuid),
        'app-content--msteams': isTeams(),
      })}
    >
      <LayoutContextProvider history={history} menuConfig={{}} isTeamsDark={isTeamsDark}>
        {/* {userRoleGuid && isEditorial(userRoleGuid) && <BarMenuContainer />} */}
        <Suspense fallback={<Loading />}>
          <Switch>
            {renderRoutes(externalRoutes)}
            {!isAuthorized ? (
              /* Redirect to `/auth` when user is not authorized */
              <Switch>
                {renderRoutes(publicRoutes)}
                <Redirect to={ROUTES.AUTH_LOGIN} />
              </Switch>
            ) : (
              <Switch>
                <Redirect exact from="/" to={ROUTES.HOME} />
                {isLocalhost() && renderRoutes(localhostRoutes)}
                {isTeams() && <Redirect exact from="/" to={teamsContentUrl} />}
                {isTeams() && <Redirect exact from={ROUTES.HOME} to={teamsContentUrl} />}
                {renderRoutes(privateRoutes)}
                <Redirect from={ROUTES.AUTH_LOGIN_TOKEN} to={redirectFromLoginWithToken()} />
                <Redirect from={ROUTES.SAML_CALLBACK} to={redirectFromLoginWithToken()} />
                <Redirect from={ROUTES.AUTH_LOGIN} to={ROUTES.HOME} />
                <Redirect from={ROUTES.AUTH_SIGNUP} to={ROUTES.HOME} />
                <Route path="*" component={NotFound} />
              </Switch>
            )}
          </Switch>
        </Suspense>
      </LayoutContextProvider>
    </div>
  );
});
