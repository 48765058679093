export const ROUTES = {
  ANSWER_TEST: '/answer-test',
  ASSESSMENT: '/course/:courseGuid/lesson/:lessonGuid/assessment/:assessmentGuid',
  AUTH_LOGIN: '/auth/login',
  AUTH_LOGIN_TOKEN: '/auth/login/:token',
  AUTH_SIGNUP: '/auth/signup',
  CALENDAR: '/calendar',
  CALENDAR_COURSE: '/calendar/:course',
  CONTENT: '/course/:courseGuid/lesson/:lessonGuid/content/:contentGuid',
  COURSE: '/course/:guid',
  COURSE_GRADES: '/course/:guid/grades',
  COURSE_PROGRAM: '/course/:guid/program',
  COURSE_RESOURCES: '/course/:guid/resources',
  DEEPLINK: '/deeplink',
  DEEPLINK_TOKEN: '/auth/login/:token/deeplink',
  ERROR: '/error',
  RESOURCES_PAGE: '/course/:guid/resources/:idResourcesSection/:idResourcesGroup',
  GROUP_RESOURCES_PAGE: '/course/:guid/resources/:idResourcesSection/:idResourcesGroup',
  HOME: '/home',
  KANBAN: '/course/:courseGuid/kanban/:lessonGuid',
  LEMON_TEST: '/lemon-test',
  LESSON: '/course/:courseGuid/lesson/:lessonGuid',
  LICENSE: '/license',
  LOGOUT: '/logout',
  NEW_CONTENT: '/course/:courseGuid/lesson/:lessonGuid/new-content',
  NEW_QUESTION: '/course/:courseGuid/lesson/:lessonGuid/assessment/:assessmentGuid/new-question/:lemonadeType',
  NOT_FOUND: '*',
  OIDC_CALLBACK: '/oidc/callback/:token',
  PRIVACY: '/privacy',
  PROFILE: '/profile',
  CREDITS: '/credits',
  PROJECTION: '/course/:courseGuid/lesson/:lessonGuid/projection',
  PROJECTION_UNIT: '/course/:courseGuid/unit/:unitGuid/lesson/:lessonGuid/projection',
  PROJECTION_POWER: '/course/:courseGuid/lesson/:lessonGuid/projection/:unitGuid/power',
  PROJECTION_MINT: '/course/:courseGuid/lesson/:lessonGuid/projection/:unitGuid',
  QUESTION: '/course/:courseGuid/lesson/:lessonGuid/assessment/:assessmentGuid/question/:contentGuid',
  RECOVER_PASSWORD: '/recover-password',
  REMOTE: '/remote/:shortToken',
  RESULTS_ASSESSMENT: '/course/:courseGuid/lesson/:lessonGuid/results-assessment/:assessmentGuid',
  RESULTS_ASSESSMENT_2: '/results-assesment/:guid',
  RESULTS_ASSESSMENT_INVIDIDUAL: '/results-assesment-individual/course/:courseGuid/:assessmentGuid/user/:userGuid',
  RESULTS_SCORM: '/course/:courseGuid/results-scorm/:itemGuid',
  RESULTS_SCORM_INDIVIDUAL: '/results-scorm-individual/:itemGuid/user/:userGuid',
  RESULTS_XAPI: '/course/:courseGuid/results-xapi/:itemGuid',
  RESULTS_XAPI_INDIVIDUAL: '/results-xapi-individual/:itemGuid/user/:userGuid',
  SAML_CALLBACK: '/saml/callback/:token',
  SAML_ERROR: '/saml/callback/error',
  SINGLE_ACTIVITY: '/single-activity/:courseGuid/:lessonItemGuid/:contentGuid',
  SINGLE_ACTIVITY_RECORD: '/single-activity/:courseGuid/record/:contentGuid/:referenceGuid',
  TASKS: '/tasks',
  TEAMS_CONFIG: '/config',
  TEAMS_TAB_AUTH_END: '/tab-auth-end',
  TEAMS_TAB_AUTH_START: '/tab-auth-start',
  TERMS: '/terms',
  TEST_COMPONENT: '/test-component',
  TOOLS: '/tools/:courseProjectGuid',
  UI: '/ui',
  UNIT_LESSON: '/course/:courseGuid/unit/:unitGuid/lesson/:lessonGuid',
  VIEWER: '/viewer/:guid',
  VIEWER_FULL: '/viewerfull/:guid',
  VIEWER_V1: '/viewer-v1/:guid',
  VIEWER_FULL_V1: '/viewerfull-v1/:guid',
};
